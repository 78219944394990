<template>
    <div class="layout-header-section d-lg-flex d-block justify-content-between">
        <div class="header-label col-lg-5 col-12">Email Logs</div>
        <div class="
        col-lg-7 col-12
        text-end
        d-flex
        justify-content-end
        custom-flex-cloumn-mob
      ">
            <button type="button" @click="emailLogFilterModalOpen()" class="header-filter-btn-outer btn btn-light"
                title="Filter">
                <span class="custom-filter-apply-alert"></span>
                <i class="pi pi-filter-fill custom-text-primary" aria-hidden="true"></i>
            </button>
        </div>
    </div>
    <div class="custom-ultima-datatable" style="height: calc(100vh - 141px)">
        <DataTable :value="emailLogsList" :scrollable="true" columnResizeMode="fit" scrollHeight="flex" :paginator="true"
            :rows="10" :lazy="true" dataKey="la1" :rowHover="true" :totalRecords="totalRecords" @page="changePage($event)"
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            responsiveLayout="scroll" currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
            :loading="loading">
            <template v-if="!loading" #empty>No records found.</template>
            <template #loading><i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i></template>
            <Column field="datatime" header="Sent Date Time" headerStyle="width: 17%" bodyStyle="width: 17%">
                <template #body="{ data }">
                    <div>
                        {{ format_datetime(data.la23) }}
                    </div>
                </template>
            </Column>
            <Column field="mailid" header="Mail Id" headerStyle="width: 20%" bodyStyle="width: 20%">
                <template #body="{ data }">
                    <div>{{ data.la39 ? data.la39 : "N/A" }}
                    </div>
                </template>
            </Column>
            <Column field="mailsubject" header="Mail Subject" headerStyle="width: 27%" bodyStyle="width: 27%">
                <template #body="{ data }">
                    <div>
                        {{ data.la12 ? data.la12 : "N/A" }}
                    </div>
                </template>
            </Column>
            <Column field="votername" header="Voter Name" headerStyle="width: 17%" bodyStyle="width: 17%">
                <template #body="{ data }">
                    <div class="text-capitalize">{{ data.la19 ? data.la19 : "N/A" }}</div>
                </template>
            </Column>
            <Column field="charges" header="Charges" headerStyle="width: 12%" bodyStyle="width: 12%">
                <template #body="{ data }">
                    <div>{{ data.la38 ? data.la38 : "N/A" }}</div>
                </template>
            </Column>
            <Column field="action" header="Action" class="justify-content-center" headerStyle="width: 7%"
                bodyStyle="width: 7%">
                <template #body="{ data }">
                    <button type="button" title="View Details" class="btn custom-outline-view-btn me-2"
                        @click="viewEmailDetailModalOpen(data)">
                        <i class="pi pi-eye call-btn-color"></i>
                    </button>
                </template>
            </Column>
        </DataTable>
    </div>

    <!-- View Email Body start here -->
    <div class="modal-mask" v-if="viewemaildetailmodalstatus">
        <div class="modal-md modal-dialog-scrollable modal-fullscreen
        ms-auto
        custom-modal-outer">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="staticBackdropLabel">Email Details</h5>
                    <button type="button" class="btn-close" @click="viewEmailDetailModalClose()"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-12">
                            <div class="email-label-group ">
                                <span class="from-label-value">Subject : </span>
                                <span class="form-label">{{ emailsubject }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-12">
                            <div class="email-label-group">
                                <div class="row">
                                    <div class="col-lg-6 col-md-6 col-12">
                                        <div class="d-flex align-items-center">
                                            <div class="flex-shrink-0">
                                                <img src="/assets/images/profile.svg"
                                                    class="profile-header-avatar rounded-circle" alt="" width="30"
                                                    height="30" />
                                            </div>
                                            <div class="flex-grow-1 ms-2 text-capitalize"> {{ emailvoter }} </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-12 d-flex align-items-center justify-content-end">
                                        <div class="text-end">
                                            <span class="from-label">{{ format_datetime(emaildatetime) }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-12">
                            <div class="col-lg-12 col-md-12 col-12">
                                <div class="email-label-group ">
                                    <div class="from-label-value mb-2">Email Body :</div>
                                    <div class="form-label">{{ emailbody }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- View Email Body End here -->
    <!-- filter start here -->
    <div class="modal-mask" v-if="emaillogfiltermodalsflag">
        <div class="
        modal-sm modal-dialog-scrollable modal-fullscreen
        ms-auto
        custom-modal-outer
      ">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="staticBackdropLabel">Filter By</h5>
                    <button type="button" class="btn-close" @click="emailLogFilterModalClose()"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-12">
                            <div class="custom-form-group">
                                <label class="form-label">From Date</label>
                                <Calendar v-model="fromdatemail" :showIcon="true" class="custom-ultima-calendar"
                                    placeholder="DD/MM/YYYY" dateFormat="dd/mm/yy" :maxDate="todaysDate"
                                    :manualInput="false" :monthNavigator="true"
                                    :yearRange="'2020:' + new Date().getFullYear()" appendTo="body" :yearNavigator="true" />
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-12">
                            <div class="custom-form-group">
                                <label class="form-label">To Date</label>
                                <Calendar v-model="todatemail" :disabled="fromdatemail == null" :showIcon="true"
                                    class="custom-ultima-calendar" placeholder="DD/MM/YYYY" dateFormat="dd/mm/yy"
                                    :minDate="fromdatemail" :maxDate="todaysDate" :manualInput="false"
                                    :monthNavigator="true" :yearRange="'2020:' + new Date().getFullYear()" appendTo="body"
                                    :yearNavigator="true" />
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-12">
                            <div class="custom-form-group">
                                <label class="form-label">Mail Id</label>
                                <input type="email" @keypress="spaceNotAllowed($event)" v-model="la14" class="form-control"
                                    placeholder="Enter Mail Id" maxlength="30" autocomplete="off" />
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-12">
                            <div class="custom-form-group">
                                <label class="form-label">Charges Status</label>
                                <div class="custom-group-radio-box-btn">
                                    <input type="radio" class="btn-check" v-model="la37" name="gender-option" id="freecost"
                                        autocomplete="off" value="1" />
                                    <label class="btn custom-radio-box-btn text-capitalize" for="freecost">Free of
                                        Cost</label>
                                    <input type="radio" class="btn-check" v-model="la37" name="gender-option" id="chargeble"
                                        autocomplete="off" value="2" />
                                    <label class="btn text-capitalize" for="chargeble">Chargeble</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <div class="col-12 m-0">
                        <div class="d-flex justify-content-between">
                            <button class="btn filter-apply-btn"
                                :disabled="fromdatemail == null && todatemail == null && la14 == '' && la37 == ''" @click="
                                    btnFilterSearch(la14, la37)
                                    ">
                                Apply Filter
                            </button>
                            <button class="btn btn-link filter-reset-btn" @click="resetVoterFilter">
                                Reset All
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- filter end here -->
</template>
<script>
import ApiService from "../../../service/ApiService";
import commonMixin from "../../../mixins/commonMixin.js";
import moment from 'moment';
export default {
    mixins: [commonMixin],
    data() {
        return {
            emailLogsList: null,
            totalRecords: 0,
            page_no: 0,
            loading: false,
            viewemaildetailmodalstatus: false,
            emailsubject: '',
            emailbody: '',
            emaildatetime: '',
            emailvoter: '',
            emaillogfiltermodalsflag: false,
            todaysDate: new Date(),
            la14: '',
            la37: '',
            fromdatemail: null,
            todatemail: null,
        };
    },
    mounted() {
        this.getTransactionalEmailLogs({
            page_no: this.page_no, fromdatemail: this.fromdatemail,
            todatemail: this.todatemail,
            la14: this.la14,
            la37: this.la37,
        });
    },
    ApiService: null,
    created() {
        this.ApiService = new ApiService();
    },
    methods: {
        getTransactionalEmailLogs(e) {
            this.loading = true;
            this.ApiService.getTransactionalEmailLogs(e).then((items) => {
                if (items.status == 200) {
                    this.emailLogsList = items.data;
                    this.totalRecords = items.count;
                    this.loading = false;
                } else {
                    this.loading = false;
                    this.emailLogsList = null;
                    this.totalRecords = 0;
                }
            });
        },
        changePage(event) {
            this.page_no = event.page;
            this.getTransactionalEmailLogs({
                page_no: this.page_no, fromdate: this.fromdatemail,
                todate: this.todatemail, la14: this.la14,
                la37: this.la37
            });
        },
        viewEmailDetailModalOpen(e) {
            this.viewemaildetailmodalstatus = true;
            this.emailsubject = e.la12;
            this.emailbody = e.la13;
            this.emaildatetime = e.la23;
            this.emailvoter = e.la19;
        },
        viewEmailDetailModalClose() {
            this.viewemaildetailmodalstatus = false;
            this.emailsubject = '';
            this.emailbody = '';
            this.emaildatetime = '';
        },
        format_datetime(value) {
            if (value) {
                return moment(String(value)).format("DD/MM/YYYY @ hh:mm A");
            } else {
                return 'N/A';
            }
        },
        spaceNotAllowed(e) {
            var key = e.keyCode;
            if (key == 32) {
                e.preventDefault();
                return false;
            }
        },
        emailLogFilterModalOpen() {
            this.emaillogfiltermodalsflag = true;
            if (this.fromdatemail != null) {
                this.fromdatemail = new Date(this.fromdatemail);
            }
            if (this.todatemail != null) {
                this.todatemail = new Date(this.todatemail);
            }
        },
        emailLogFilterModalClose() {
            this.emaillogfiltermodalsflag = false;
        },
        btnFilterSearch(la14, la37
        ) {
            if (this.fromdatemail != null && this.todatemail != null) {
                this.fromdatemail = moment(this.fromdatemail).format('YYYY-MM-DD');
                this.todatemail = moment(this.todatemail).format('YYYY-MM-DD');
            }
            this.la14 = la14;
            this.la37 = la37;
            this.emaillogfiltermodalsflag = false;
            this.getTransactionalEmailLogs({
                page_no: this.page_no,
                fromdate: this.fromdatemail,
                todate: this.todatemail,
                la14: this.la14,
                la37: this.la37,
            });
        },
        resetVoterFilter() {
            this.fromdatemail = null;
            this.todatemail = null;
            this.la14 = '';
            this.la37 = '';
            this.getTransactionalEmailLogs({
                page_no: this.page_no,
            });
            this.emaillogfiltermodalsflag = false;
        },
    },
};
</script>
<style scoped>
.status-active {
    background: #c8e6c9;
    border-radius: 2px;
    letter-spacing: 0.3px;
    color: #256029;
    opacity: 1;
    font-size: 12px;
    font-family: "AcuminPro-Bold";
    padding: 0.3em 0.2rem 0.2em 0.2rem;
    text-transform: capitalize;
    border: 1px solid #4daa53;
    line-height: 12px;
}

.status-inactive {
    background: #ffcdd2;
    border-radius: 2px;
    letter-spacing: 0.3px;
    color: #c63737;
    opacity: 1;
    font-size: 12px;
    font-family: "AcuminPro-Bold";
    padding: 0.3em 0.2rem 0.2em 0.2rem;
    text-transform: capitalize;
    border: 1px solid #d7a5a5;
    line-height: 12px;
}

.email-label-group {
    padding: 8px 16px;
    background: #e4e7ff 0% 0% no-repeat padding-box;
    border: 1px solid #bec2db;
    margin-bottom: 10px;
}

.email-label-group .form-label {
    font-family: "AcuminPro-Regular";
    font-size: 16px;
    letter-spacing: 0px;
    color: #4a5463;
    margin-bottom: 6px;
}

.email-label-group .from-label-value {
    font-family: "AcuminPro-SemiBold";
    font-size: 16px;
    letter-spacing: 0.28px;
    color: #2b2e38;
    line-height: 22px;
}

.profile-header-avatar {
    background: #b3bae1 0% 0% no-repeat padding-box;
    padding: 2px;
}</style>